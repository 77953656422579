<template>
    <v-row>
        <v-col>
            <v-card>
                <v-toolbar>
                    <v-btn
                        density="comfortable"
                        prepend-icon="mdi-delete"
                        @click="confirmDialog = true"
                    >Slett alle disse profilene</v-btn>
                    <v-dialog v-model="confirmDialog" persistent max-width="290">
                        <v-card>
                            <v-card-text>Er du sikker på at du vil slette alle disse profilene?</v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="green darken-1" flat @click="confirmDialog = false">Avbryt</v-btn>
                                <v-btn color="green darken-1" flat @click="deleteBlacklistedProfiles()">Ok</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
                <v-skeleton-loader v-if="props.loading"></v-skeleton-loader>
                <v-data-table-server
                    :page="paging.pageNumber"
                    :headers="headers"
                    :items="profiles"
                    :items-per-page="paging.pageSize"
                    :items-per-page-options="DefaultPerPageOptions"
                    :items-length="paging.totalItemCount"
                    @update:itemsPerPage="onItemsPerPageChange"
                    @update:page="onPageChange"
                    v-else
                    >
                    <template #item.phone="{ item }">
                        {{ formatters.toFullPhone(item.raw.phone) }}
                    </template>
                    <template #item.link="{ item }">
                        <a class="card-link" :href="getProfileLink(item.raw.links)" target="blank">
                            <v-tooltip activator="parent" location="start">Åpne profil</v-tooltip>
                            <v-icon icon="mdi-open-in-new" />
                        </a>
                    </template>
                </v-data-table-server>
            </v-card>
        </v-col>
    </v-row>
</template>

<script lang="ts" setup>
import { VSkeletonLoader, VDataTableServer } from 'vuetify/labs/components';
import { getLink } from '@/features/shared/LinkHelper';
import { Link, Paging } from '../shared/models';
import { Profile } from '../profiles/profilesModels';
import { ProfileRels } from '../profiles/rels';
import { useFormatters } from '../shared/formatters';
import { DefaultPerPageOptions } from '@/features/shared/models';
import { ref } from 'vue';

const formatters = useFormatters();

interface Props {
    profiles: Array<Profile>;
    paging: Paging;
    loading: boolean;
}
type Emits = {
    (e: 'perPageChange', pageSize: number): void;
    (e: 'pageChange', pageNumber: number): void;
    (e: 'deleteBlacklistedProfiles'): void;
};

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const headers = [
    {
        title: 'Fornavn',
        key: 'firstName',
        sortable: false
    },
    {
        title: 'Etternavn',
        key: 'lastName',
        sortable: false
    },
    {
        title: 'E-post',
        key: 'email',
        sortable: false
    },
    {
        title: 'Telefon',
        key: 'phone',
        sortable: false
    },
    {
        title: '',
        key: 'link',
        sortable: false
    }
];

const confirmDialog = ref(false);

function onPageChange(pageNumber: number) {
    emit('pageChange', pageNumber);
}

function onItemsPerPageChange(pageSize: number) {
    emit('perPageChange', pageSize);
}

function deleteBlacklistedProfiles() {
    emit('deleteBlacklistedProfiles');
    confirmDialog.value = false;
}

function getProfileLink(links: Array<Link>) {
    const cardLink = getLink(ProfileRels.GetProfile, links);
    return cardLink.href;
}
</script>

<style lang="scss" scoped>
.card-link {
    color: #000000;
}
</style>
