import {defineStore} from 'pinia';
import {Profile, ProfileUser} from '@/features/profiles/profilesModels';
import {useCommonStore} from '@/features/shared/commonStore';
import {useAxios} from '@/features/shared/axios';
import {DefaultPaging, Link, ListResource} from '@/features/shared/models';
import {ProfileRels} from '@/features/profiles/rels';
import {getLink} from '@/features/shared/LinkHelper';

const EmptyProfile = { phone: { countryCode: '+47', number: '' } } as Profile;
const EmptyProfileUser = {} as ProfileUser;

export const useProfilesStore = defineStore('profiles', {
    state: () => ({
        profiles: new Array<Profile>(),
        currentProfile: EmptyProfile,
        currentProfileLinks: new Array<Link>(),
        currentProfileUser: EmptyProfileUser,
        paging: { ...DefaultPaging },
        links: new Array<Link>(),
        searchPhrase: '',
        searchId: ''
    }),
    getters: {
        getProfileById: state => {
            return (id: string) => {
                return state.profiles.find((profile: any) => profile.id === id) as Profile;
            };
        },
        getProfileLinks: state => {
            return state.currentProfileLinks;
        },
        isValidProfile: state => {
            return (
                !!state.currentProfile.firstName &&
                !!state.currentProfile.lastName &&
                !!state.currentProfile.email &&
                !!state.currentProfile.phone?.number
            );
        },
        getFullName: state => {
            return `${state.currentProfile?.firstName} ${state.currentProfile?.lastName}`;
        }
    },
    actions: {
        clearProfile() {
            this.currentProfile = EmptyProfile;
        },
        async loadProfile(id: string) {
            let profile = this.getProfileById(id);
            if (!profile) {
                this.searchId = id;
                await this.loadProfiles();
                profile = this.profiles[0];
            }

            await this.loadCurrentProfile(profile);
            await this.loadCurrentProfileUser();
            this.searchId = '';
        },
        async loadCurrentProfile(profile: any) {
            const axios = useAxios();
            const link = getLink(ProfileRels.GetProfile, profile.links);
            const response = await axios.requestByLink(link);

            this.currentProfile = response.data.data;
            this.currentProfileLinks = response.data.links;
        },
        async loadCurrentProfileUser() {
            const axios = useAxios();
            const link = getLink(ProfileRels.GetProfileUser, this.currentProfileLinks);
            const response = await axios.requestByLink(link);
            this.currentProfileUser = response.data.data;
        },
        async loadProfiles() {
            const common = useCommonStore();
            const axios = useAxios();
            const link = common.getLink(ProfileRels.GetProfiles);

            if (!link) throw new Error(`${ProfileRels.GetProfiles} link not found`);

            const requestParams = new URLSearchParams();
            requestParams.append('page.number', this.paging.pageNumber.toString());
            requestParams.append('page.size', this.paging.pageSize.toString());
            if (this.searchPhrase) requestParams.append('searchPhrase', this.searchPhrase);
            if (this.searchId) requestParams.append('id', this.searchId);

            const response = await axios.requestByLink<ListResource<Profile>>(link, {
                params: requestParams
            });
            this.profiles = response.data.data ?? new Array<Profile>();
            this.paging = response.data.paging;
            this.links = response.data.links;
        },
        async updateProfile() {
            const axios = useAxios();
            const link = getLink(ProfileRels.UpdateProfile, this.currentProfileLinks);

            const profileData = { ...this.currentProfile };
            const result = await axios.requestByLink(link, { data: profileData });
            this.currentProfile = result.data;
        },
        async createProfile() {
            const common = useCommonStore();
            const axios = useAxios();
            const link = common.getLink(ProfileRels.CreateProfile);

            if (!link) throw new Error(`${ProfileRels.CreateProfile} link not found`);

            const profileData = { ...this.currentProfile };
            await axios.requestByLink(link, { data: profileData });
        },
        async deleteProfile() {
            const axios = useAxios();
            const link = getLink(ProfileRels.DeleteProfile, this.currentProfileLinks);

            await axios.requestByLink(link);
        },
        async updateProfileUser() {
            const axios = useAxios();
            const link = getLink(ProfileRels.UpdateProfileUser, this.currentProfileLinks);

            const userData = { ...this.currentProfileUser };
            const result = await axios.requestByLink(link, { data: userData });
            this.currentProfileUser = result.data;
        },
        async sendEmailConfirmation() {
            const axios = useAxios();
            const link = getLink(ProfileRels.SendEmailConfirmation, this.currentProfileLinks);
            const data = { email: this.currentProfile.email };
            await axios.requestByLink(link, { data: data });
        },
        async sendForgotPassword() {
            const axios = useAxios();
            const link = getLink(ProfileRels.SendForgotPassword, this.currentProfileLinks);
            const data = { email: this.currentProfile.email };
            await axios.requestByLink(link, { data: data });
        },
        async changePassword(password: string) {
            const axios = useAxios();
            const link = getLink(ProfileRels.UpdateProfileUserCredentials, this.currentProfileLinks);

            const data = { password: password };
            await axios.requestByLink(link, { data: data });
        }
    }
});
