<template>
    <v-container>
        <v-row>
            <v-col>
                <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
                <v-btn variant="tonal" :to="backRoute" prepend-icon="mdi-arrow-left-circle">Tilbake til listen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="loading.form">
                <v-row>
                    <v-skeleton-loader class="profile-blacklist-loader" type="heading, list-item, list-item, list-item"> </v-skeleton-loader>
                </v-row>
            </v-col>
            <v-col v-else-if="store.currentProfileBlacklist.id">
                <v-tabs v-model="tab">
                    <v-tab value="form">Svarteliste-oppføring</v-tab>
                    <v-tab value="profiles">Profiler</v-tab>
                </v-tabs>
                <v-window v-model="tab" class="edit-window" :touch="false">
                    <v-window-item value="form">
                        <v-card class="profile-blacklist-form">
                            <v-card-text>
                                <v-form ref="form" v-model="isFormValid" :class="{ loading: loading.submit }" @submit.prevent="submit">
                                    <div><v-text-field v-model="store.currentProfileBlacklist.emailRegex.pattern" label="E-post regex-mønster" :rules="rules"></v-text-field></div>
                                    <div class="controls">
                                        <v-btn type="submit" :disabled="submitDisabled" color="primary">Submit</v-btn>
                                        <v-btn
                                            density="comfortable"
                                            icon="mdi-delete"
                                            @click="confirmDialog = true"
                                        ></v-btn>
                                        <v-dialog v-model="confirmDialog" persistent max-width="290">
                                            <v-card>
                                                <v-card-text>Er du sikker på at du vil slette denne svarteliste-oppføring?</v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="green darken-1" flat @click="confirmDialog = false">Avbryt</v-btn>
                                                    <v-btn color="green darken-1" flat @click="deleteProfileBlacklist()">Ok</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </div>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-window-item>
                    <v-window-item value="profiles">
                        <blacklisted-profiles
                            :profiles="store.currentProfiles"
                            :paging="store.profilesPaging"
                            :loading="loading.profiles"
                            @page-change="onProfilesPageChange"
                            @per-page-change="onProfilesPerPageChange"
                            @delete-blacklisted-profiles="onDeleteBlacklistedProfiles">
                        </blacklisted-profiles>
                    </v-window-item>
                </v-window>
            </v-col>
        </v-row>
    </v-container>
</template>
<script lang="ts" setup>
import { VSkeletonLoader } from 'vuetify/labs/components';
import { computed, onMounted, ref, watch } from 'vue';
import { AlertTypes, Events, useEmitter } from '../shared/useEmitter';
import { useProfileBlacklistStore } from './profileBlacklistStore';
import { ProfileBlacklistRels } from './rels';
import { isRequired } from '../shared/formValidationRules';
import { useRoute, useRouter } from 'vue-router';
import { getErrors } from '../shared/ErrorHelper';
import BlacklistedProfiles from './BlacklistedProfiles.vue';

const eventBus = useEmitter();
const route = useRoute();
const store = useProfileBlacklistStore();
const router = useRouter();

const tab = ref('');
watch(tab, newValue => {
    router.replace({ ...route, query: { tab: newValue } });
});
const isFormValid = ref<boolean | null>();
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: { id: string | number; errorMessages: string[] }[];
        }>;
    }
>();
const confirmDialog = ref(false);

const breadcrumbs = [
    {
        title: 'Svarteliste',
        disabled: false,
        to: {
            name: ProfileBlacklistRels.GetProfileBlacklists
        }
    },
    {
        title: 'Rediger svarteliste-oppføring'
    }
];
const backRoute = ref({ name: ProfileBlacklistRels.GetProfileBlacklists, query: {} });
const profileBacklistId = route.params.id.toString();
const rules = [isRequired];
let disabled = false;

const loading = ref({
    form: true,
    submit: false,
    profiles: true,
});

watch(isFormValid, newValue => {
    if (newValue === null) {
        disabled = false;
    } else {
        disabled = !newValue;
    }
});

onMounted(async () => {
    if (route.query?.tab) tab.value = route.query.tab.toString();

    await store.loadProfileBacklist(profileBacklistId);

    loading.value.form = false;

    await store.loadBlacklistedProfiles();

    loading.value.profiles = false;
});

const submitDisabled = computed(() => {
    return !store.isValidProfileBlacklist || disabled;
});

async function submit() {
    loading.value.submit = true;
    const validation = await form.value?.validate();
    if (!validation?.valid) return;

    try {
        await store.updateProfileBlacklist();
        loading.value.submit = false;
        const alertContent = { type: AlertTypes.success, text: 'Svarteliste-oppføring oppdatert' };
        eventBus.emitter.emit(Events.alert, alertContent);
        router.push({ name: ProfileBlacklistRels.GetProfileBlacklists });
    } catch (err: any) {
        loading.value.submit = false;
        const data = err.response.data;
        const errors = getErrors(data);

        if (errors !== null) {
            eventBus.emitter.emit(Events.alert, errors);
        } else {
            throw err;
        }
    }
}

async function deleteProfileBlacklist() {
    confirmDialog.value = false;
    await store.deleteProfileBlacklist();
    const alertContent = { type: AlertTypes.success, text: 'Svarteliste-oppføring slettet' };
    eventBus.emitter.emit(Events.alert, alertContent);
    await router.push({ name: ProfileBlacklistRels.GetProfileBlacklists });
}

async function onProfilesPageChange(pageNumber: number) {
    store.profilesPaging.pageNumber = pageNumber;

    loading.value.profiles = true;
    await store.loadBlacklistedProfiles();
    loading.value.profiles = false;
}

async function onProfilesPerPageChange(pageSize: number) {
    store.profilesPaging.pageSize = pageSize;

    loading.value.profiles = true;
    await store.loadBlacklistedProfiles();
    loading.value.profiles = false;
}

async function onDeleteBlacklistedProfiles() {
    loading.value.profiles = true;
    await store.deleteBlacklistedProfiles();
    tab.value = 'form';
    loading.value.profiles = false;
}
</script>

<style lang="scss" scoped>
.profile-blacklist-loader {
    width: 100%;
    background: transparent;
    max-width: 700px;
}

.profile-blacklist-form {
    width: 100%;
    max-width: 700px;
}

.loading {
    opacity: 0.5;
}

.controls {
    display: flex;
    justify-content: space-between;
}

.edit-window {
    padding: 12px 1px;
}
</style>
