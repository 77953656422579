<template>
    <v-app>
        <app-shell :app-info="appInfo" :current-user="currentUser" :loading="loading" :menu-items="menuItems">
            <router-view :key="route.path" />
            <alert-popup ref="alertPopupRef" />
        </app-shell>
        <silent-login v-if="userLoaded && !userStore.user.isLoggedIn" />
    </v-app>
</template>

<script lang="ts" setup>
import { AppShell } from '@geta/kolumbus-admin-frontend/components';
import type { MenuItem } from '@geta/kolumbus-admin-frontend/components/AppShell/AppShell.vue';
import UserInfo from '@geta/kolumbus-admin-frontend/models/UserInfo';
import AppInfo from '@geta/kolumbus-admin-frontend/models/AppInfo';
import { onMounted, ref, onErrorCaptured, Ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import SilentLogin from '@/features/authentication/SilentLogin.vue';
import { useUserStore } from '@/features/authentication/userStore';
import { useCommonStore } from '@/features/shared/commonStore';
import { ProfileRels } from '@/features/profiles/rels';
import AlertPopup from '@/features/shared/components/AlertPopup.vue';
import { useEmitter, Events, AlertTypes } from './features/shared/useEmitter';
import { CommonRels } from '@/features/shared/rels';
import { ProfileBlacklistRels } from './features/profileBlacklist/rels';

const appInfo: AppInfo = {
    name: 'Fort Admin',
    copyright: 'Geta Digital',
    version: '0.1-dev'
};

const menuItems = ref<Array<MenuItem>>([
    {
        icon: 'mdi-account-multiple',
        title: 'Profiler',
        to: {
            name: ProfileRels.GetProfiles
        }
    }
]) as Ref<Array<MenuItem>>;
const loading = ref(true);
const userLoaded = ref(false);

const currentUser: UserInfo = {
    displayName: '',
    detailedInfo: '',
    logoutLink: ''
};
const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const commonStore = useCommonStore();
const eventBus = useEmitter();
const alertPopupRef = ref<null | { setAlert: (e: any) => null }>(null);
let link: any = null;

function addAppMenuLinks() {
    const blacklistLink = commonStore.getLink(ProfileBlacklistRels.GetProfileBlacklists);
    if (blacklistLink) {
        menuItems.value.push({
            icon: 'mdi-account-multiple',
            title: 'Svarteliste',
            to: {
                name: ProfileBlacklistRels.GetProfileBlacklists
            }
        });
    }
}

function addMenuExternalLinks() {
    const boomerangLink = commonStore.getLink(CommonRels.BoomerangExternalUrl);
    if (boomerangLink) {
        menuItems.value.push({
            icon: 'mdi-open-in-new',
            title: 'Boomerang',
            href: boomerangLink.href,
            target: '_blank'
        });
    }

    const identityLink = commonStore.getLink(CommonRels.IdentityExternalUrl);
    if (identityLink) {
        menuItems.value.push({
            icon: 'mdi-open-in-new',
            title: 'Identity',
            href: identityLink.href,
            target: '_blank'
        });
    }

    const localizationLink = commonStore.getLink(CommonRels.LocalizationAdminExternalUrl);
    if (localizationLink) {
        menuItems.value.push({
            icon: 'mdi-open-in-new',
            title: 'Localization',
            href: localizationLink.href,
            target: '_blank'
        });
    }
}

function initUser() {
    currentUser.logoutLink = userStore.user.logoutUrl;

    const userProfile = userStore.profile;
    if (!userProfile) {
        currentUser.displayName = userStore.user.name;
        return;
    }

    currentUser.profileRoute = { name: ProfileRels.UpdateProfile, params: { id: userProfile?.id } };
    currentUser.displayName = `${userProfile.firstName} ${userProfile.lastName}`;
}

onMounted(async () => {
    if (commonStore.links.length === 0) await commonStore.loadLinks();

    await userStore.loadUser();
    await userStore.loadUserProfile();
    userLoaded.value = true;
    eventBus.emitter.on(Events.alert, e => alertPopupRef.value?.setAlert(e));

    initUser();
    addAppMenuLinks();
    addMenuExternalLinks();

    setTimeout(() => {
        loading.value = !userStore.user.isLoggedIn; // keep loading while logging in
    }, 1000);

    link = commonStore.getLink(ProfileRels.GetProfiles);
    if (!link && userStore.user.isLoggedIn) {
        menuItems.value = [];
        currentUser.profileRoute = undefined;
        await router.push({ name: 'AccessDenied' });
    }
});

onErrorCaptured(err => {
    const alertContent = { type: AlertTypes.error, text: err.message };
    eventBus.emitter.emit(Events.alert, alertContent);

    return false;
});
</script>

<style lang="scss">
.v-breadcrumbs {
    font-size: 1.5rem;
    padding-left: 0;

    .v-breadcrumbs-item--disabled {
        opacity: 1;
        font-weight: bold;
    }
}
</style>
