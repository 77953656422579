import ProfileBlacklistList from '@/features/profileBlacklist/ProfileBlacklistList.vue';
import ProfileBlacklistEdit from '@/features/profileBlacklist/ProfileBlacklistEdit.vue';
import ProfileBlacklistCreate from '@/features/profileBlacklist/ProfileBlacklistCreate.vue';
import { ProfileBlacklistRels } from '@/features/profileBlacklist/rels';

export const ProfileBlacklistRoutes = [
    {
        path: '/',
        redirect: '/profile-blacklist'
    },
    {
        path: '/profile-blacklist',
        name: ProfileBlacklistRels.GetProfileBlacklists,
        component: ProfileBlacklistList
    },
    {
        path: '/profile-blacklist/:id',
        name: ProfileBlacklistRels.UpdateProfileBlacklist,
        component: ProfileBlacklistEdit
    },
    {
        path: '/profile-blacklist/create',
        name: ProfileBlacklistRels.CreateProfileBlacklist,
        component: ProfileBlacklistCreate
    }
];
