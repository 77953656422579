<template>
    <v-container>
        <v-row>
            <v-col>
                <v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs>
                <v-btn variant="tonal" :to="backRoute" prepend-icon="mdi-arrow-left-circle">Tilbake til listen</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card class="profile-blacklist-form">
                    <v-card-text>
                        <v-form ref="form" v-model="isFormValid" :class="{ loading: loading }" @submit.prevent="submit">
                            <div><v-text-field v-model="store.currentProfileBlacklist.emailRegex.pattern" label="E-post regex-mønster" :rules="rules"></v-text-field></div>
                            <v-btn type="submit" :disabled="submitDisabled" color="primary">Submit</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch } from 'vue';
import { ProfileBlacklistRels } from './rels';
import { AlertTypes, Events, useEmitter } from '../shared/useEmitter';
import { getErrors } from '../shared/ErrorHelper';
import { useProfileBlacklistStore } from './profileBlacklistStore';
import { isRequired } from '../shared/formValidationRules';
import { useRouter } from 'vue-router';

const router = useRouter();
const eventBus = useEmitter();
const store = useProfileBlacklistStore();

const isFormValid = ref<boolean | null>();
const form = ref<
    HTMLElement & {
        validate: () => Promise<{
            valid: boolean;
            errors: { id: string | number; errorMessages: string[] }[];
        }>;
    }
>();
const loading = ref(false);

const breadcrumbs = [
    {
        title: 'Svarteliste',
        disabled: false,
        to: {
            name: ProfileBlacklistRels.GetProfileBlacklists
        }
    },
    {
        title: 'Ny svarteliste-oppføring'
    }
];
const backRoute = ref({ name: ProfileBlacklistRels.GetProfileBlacklists, query: {} });
const rules = [isRequired];
let disabled = false;

watch(isFormValid, newValue => {
    if (newValue === null) {
        disabled = false;
    } else {
        disabled = !newValue;
    }
});

const submitDisabled = computed(() => {
    return !store.isValidProfileBlacklist || disabled;
});

onMounted(() => {
    store.clearCurrentProfileBlacklist();
});

async function submit() {
    loading.value = true;
    const validation = await form.value?.validate();
    if (!validation?.valid) return;

    try {
        await store.createProfileBlacklist();
        loading.value = false;
        const alertContent = { type: AlertTypes.success, text: 'Svarteliste-oppføring lagret' };
        eventBus.emitter.emit(Events.alert, alertContent);
        router.push({ name: ProfileBlacklistRels.GetProfileBlacklists });
    } catch (err: any) {
        loading.value = false;
        const data = err.response.data;
        const errors = getErrors(data);

        if (errors !== null) {
            eventBus.emitter.emit(Events.alert, errors);
        } else {
            throw err;
        }
    }
}
</script>

<style lang="scss" scoped>
.profile-form {
    width: 100%;
    max-width: 700px;
}

.loading {
    opacity: 0.5;
}
</style>